import React from 'react';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import './Experience.css';

const Experience = () => {
  let experiences = require('../../api/pengalaman.json');
  let education = require('../../api/pendidikan.json');
  return (
    <section id="experience-section" className="education-experience pb-minus-70 section">
      <Container>
        <Row className="justify-content-center text-center">
          <Col lg={7} sm={12}>
            <h3 className="section-title">Pengalaman<span>Saya</span></h3>
            <hr className="section-line-border" />
            <p className="section-sub-line">What I have learned and experienced.</p>
          </Col>
        </Row>
        <Row className="justify-content-center text-center">
          <Col md={6}>
            {/* Left Side - Education */}
            <div className="education">
              <h4><FontAwesomeIcon icon={faGraduationCap} /> Riwayat Pendidikan</h4>
              <div className="text-left mt-4">
                {education.map((education, index) => (
                  <div key={index} style={{ marginBottom: '20px', borderBottom: '1px solid #ccc', paddingBottom: '20px' }}>
                    <p>
                      <strong>Universitas:</strong> {education.university}
                      <br />
                      <strong>Jurusan:</strong> {education.major}
                                 <br />
                      <strong>Tahun:</strong> {education.timeline}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col md={6}>
            {/* Right Side - Working Experience */}
             <div className="working">
              <h4><FontAwesomeIcon icon={faBriefcase} /> Pengalaman Bekerja</h4>
              <div className="text-left mt-4">
                {experiences.map((experience, index) => (
                  <div key={index} style={{ marginBottom: '20px', borderBottom: '1px solid #ccc', paddingBottom: '20px' }}>
                    <p>
                      <strong>Nama Pengalaman:</strong> {experience.name}
                      <br />
                      <strong>Link Sertifikat:</strong>{" "}
                      {experience.certificateLink ? (
                        <a href={experience.certificateLink} style={{ color: "#0000FF" }}>Lihat Sertifikat</a>
                      ) : (
                        "Tidak tersedia"
                      )}
                      <br />
                      <strong>Timeline:</strong> {experience.timeline}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Experience;
