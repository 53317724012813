import React from "react";
import './About.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col } from 'react-bootstrap';

const About = () => {
    return (
         <section className="hero-banner" data-scroll-index="1">
      <Container className="h-100">
        <Row className="align-items-center">
          <Col lg={7} md={12} sm={12}>
            <div className="hero-inner">
              <div className="hero-social">
              <a href="https://github.com/adityarizkyramadhan"><FontAwesomeIcon icon={faGithub} /></a>
              <a href="https://www.linkedin.com/in/adityaariizkyy"><FontAwesomeIcon icon={faLinkedin} /></a>
              <a href="https://www.instagram.com/adityaariizkyy"><FontAwesomeIcon icon={faInstagram} /></a>
              <a href="https://www.tiktok.com/@internalservererror.500"><FontAwesomeIcon icon={faTiktok} /></a>
              </div>
              <h1>Halo, aku Aditya Rizky</h1>
              <p>
                Saya adalah mahasiswa aktif semester enam dari Fakultas Ilmu Komputer Universitas Brawijaya. Saya sangat tertarik pada bidang backend development dan senang menggunakan bahasa pemrograman Go, JavaScript, dan Rust untuk membuat aplikasi. Selain itu saya juga tertarik dalam DevOps yang merupakan bidang yang mencakup aspek pengelolaan infrastruktur, otomatisasi, dan pemantauan dalam pengembangan perangkat lunak.
              </p>
              <a href="https://is3.cloudhost.id/storage-foto/all-docs/ADITYA%20RIZKY%20RAMADHAN-resume%20%286%29.pdf" className="default-orange-btn">Download CV<i className="ml-2 fas fa-thumbs-up"></i></a>
            </div>
          </Col>
          <Col lg={5} className="banner-profile-img" data-tilt data-tilt-max="10" data-tilt-speed="400" data-tilt-perspective="1000">
            <img src="https://jgjyjvyldoamqndazixl.supabase.co/storage/v1/object/public/web-porto/foto-profile.png" alt="Hero Image" className="img-fluid hero-img"/>
          </Col>
        </Row>
      </Container>
    </section>
    );
};

export default About;
