import React from 'react';
import { Box, Text, Image } from '@chakra-ui/react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box
      bg="black"
      color="white"
      p={4}
      textAlign="center"
      position="fixed"
      bottom="0"
      left="0"
      right="0"
      zIndex="999"
      width="100%"
    >
      <Text fontSize="sm">
        &copy; {currentYear} Aditya Rizky Ramadhan | Dibuat dengan{' '}
        <Image src="/logo192.png" alt="React logo" boxSize="20px" display="inline" /> React Js
      </Text>
    </Box>
  );
};

export default Footer;
