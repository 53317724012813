import React from 'react';
import { Container, Carousel, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import './Projects.css';

const Projects = () => {
  let projects = require('../../api/projects.json');

  return (
    <section id="projects-section" className="education-experience pb-minus-70 section">
      <Container>
        <div className="justify-content-center text-center">
          <h3 className="section-title">Riwayat<span>Project</span></h3>
          <hr className="section-line-border" />
          <p className="section-sub-line">Saya telah berhasil mengembangkan berbagai macam project, berikut ini merupakan project yang telah selesai saya buat : </p>
        </div>
        <Carousel className="justify-content-center text-center">
          {projects.map((project, index) => (
            <Carousel.Item key={index}>
              <Card className="mx-auto card-custom">
                <Card.Img variant="top" src={project.image} className="img-fluid" />
                <Card.Body>
                  <Card.Title>{project.judul}</Card.Title>
                  <Card.Text>{project.deskripsi}</Card.Text>
                    <Card.Text><strong>Teknologi:</strong> {project.teknologi}</Card.Text>
                  <Button className='mb-4 repo-btn' variant="primary" href={project.link} target="_blank">Repo Project</Button>
                </Card.Body>
              </Card>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </section>
  );
};

export default Projects;
