import React from "react";
// import { Flex, Image, Heading, Text, Link, Box, Button, Table, Tbody, Td, Th, Tr, List, ListItem, Divider } from "@chakra-ui/react";
// import '../styles/style.css';
import About from "../components/about/About";
import Experience from "../components/experience/Experience";
import Projects from "../components/projects/Projects";
import Achievments from "../components/achievments/Achievments";
import Skills from "../components/Skills/Skills";


function Home() {
    return (

       <>
            <About />
            <Experience />
            <Projects />
            <Skills />
            <Achievments />
        </>
    );
}

export default Home;
