import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import './Achievments.css';

const Achievements = () => {
  let achievements = require('../../api/achievments.json');

  return (
    <section id="achievements-section" className="education-experience pb-minus-70 section">
      <Container>
        <div className="justify-content-center text-center">
          <h3 className="section-title">Pencapaian<span>Saya</span></h3>
          <hr className="section-line-border" />
          <p className="section-sub-line">Selama menjalani perkuliahan, saya berhasil meraih berbagai pencapaian sebagai berikut : </p>
        </div>
        <Row className="justify-content-center">
          {achievements.slice(0, 3).map((achievement, index) => (
            <Col md={4} key={index} className="mb-4">
              <Card className="custom-card">
                <Card.Img variant="top" src={achievement.image} className="custom-card-img" />
                <Card.Body>
                  <Card.Title className="custom-card-title">{achievement.judul}</Card.Title>
                  <Card.Text>{achievement.deskripsi}</Card.Text>
                  {achievement.sertifikat ? (
                    <Button className='mb-4 repo-btn' variant="primary" href={achievement.sertifikat} target="_blank">Lihat Sertifikat</Button>
                  ) : (
                    <Button className='mb-4 repo-btn' variant="primary" disabled>Belum Tersedia</Button>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Achievements;
