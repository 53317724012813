import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Flex,
  Box,
  Heading,
  Spacer,
  Button,
  useDisclosure,
  Collapse,
  VStack,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const { isOpen, onToggle } = useDisclosure();
  const location = useLocation();
  const navigate = useNavigate();
  const [isSticky, setSticky] = useState(false);

  const handleMenuClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleNavClick = (sectionId) => {
    if (location.pathname === "/") {
      handleMenuClick(sectionId);
    } else {
      navigate("/");
      setTimeout(() => {
        handleMenuClick(sectionId);
      }, 100);
    }
  };

  const handleDropdownNavClick = (sectionId) => {
    onToggle();
    setTimeout(() => {
      if (location.pathname === "/") {
        handleMenuClick(sectionId);
      } else {
        navigate("/");
        setTimeout(() => {
          handleMenuClick(sectionId);
        }, 100);
      }
    }, 300);
  };

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 64) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Flex
        bg="white"
        p={2}
        px={4}
        alignItems="center"
        color="black"
        position={isSticky ? "fixed" : "static"}
        top={0}
        zIndex={999}
        width="100%"
        boxShadow={isSticky ? "0px 2px 4px rgba(0, 0, 0, 0.1)" : "none"}
      >
        <Box p="2">
          <Heading size="md" color="black">
            Logo
          </Heading>
        </Box>
        <Spacer />
        <Box display={{ base: "none", md: "flex" }} alignItems="center">
          <Flex align="center">
            <Link to="/">
              <Button variant="ghost" mr={4}>
                Home
              </Button>
            </Link>
            <Button
              variant="ghost"
              mr={4}
              onClick={() => handleNavClick("experience-section")}
            >
              Experience
            </Button>
            <Button
              variant="ghost"
              mr={4}
              onClick={() => handleNavClick("projects-section")}
            >
              Project
            </Button>
            <Button
              variant="ghost"
              mr={4}
              onClick={() => handleNavClick("skills-section")}
            >
              Skills
            </Button>
            <Button
              variant="ghost"
              mr={4}
              onClick={() => handleNavClick("achievements-section")}
            >
              Achievement
            </Button>
            <Link to="/articles">
              <Button variant="ghost" mr={4}>
                Articles
              </Button>
            </Link>
          </Flex>
        </Box>
        <Button
          variant="solid"
          onClick={onToggle}
          display={{ base: "block", md: "none" }}
          style={{ backgroundColor: "#ff5000", color: "white" }}
        >
          <FontAwesomeIcon icon={faBars} />
        </Button>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <Box
          bg="white"
          display={{ md: "none" }}
          position="fixed"
          top="64px"
          zIndex={998}
          maxHeight="calc(100vh - 64px)"
          overflowY="auto"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          width="100%"
        >
          <VStack spacing={4}>
            <Link to="/" onClick={() => handleDropdownNavClick("")}>
              <Button variant="ghost">Home</Button>
            </Link>
            <Button
              variant="ghost"
              onClick={() => handleDropdownNavClick("experience-section")}
            >
              Experience
            </Button>
            <Button
              variant="ghost"
              onClick={() => handleDropdownNavClick("projects-section")}
            >
              Project
            </Button>
            <Button
              variant="ghost"
              onClick={() => handleDropdownNavClick("skills-section")}
            >
              Skills
            </Button>
            <Button
              variant="ghost"
              onClick={() => handleDropdownNavClick("achievements-section")}
            >
              Achievement
            </Button>
            <Link to="/articles" onClick={() => handleDropdownNavClick("")}>
              <Button variant="ghost">Articles</Button>
            </Link>
          </VStack>
        </Box>
      </Collapse>
    </>
  );
};

export default Navbar;
