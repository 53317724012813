import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Card, Badge } from 'react-bootstrap'; 
import articlesData from '../api/articles.json';

const ArticleDetail = () => {
  const { id } = useParams();
  const article = articlesData.posts.find((article) => article.id === parseInt(id));

  return (
    <Container style={{ marginTop: '100px', paddingBottom: '60px' }}>
      {article ? (
        <Card className="mt-4">
          <Card.Body>
            <Card.Title className='text-center mb-2'>{article.title}</Card.Title>
            <Card.Img variant="top" src={article.img} style={{ width: '60%', margin: 'auto' }} />

            <Card.Text>{article.body}</Card.Text>
            <div>
              {article.tags.map((tag, index) => ( 
                <Badge variant="primary" className="mr-2" key={index}>{tag}</Badge> 
              ))}
            </div>
          </Card.Body>
        </Card>
      ) : (
        <div className="mt-4">
          <h2>Article Not Found</h2>
        </div>
      )}
    </Container>
  );
};

export default ArticleDetail;
