import React, { useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link
import articlesData from '../api/articles.json';

const Articles = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 6;

  // Mendapatkan data artikel untuk halaman yang sedang aktif
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = articlesData.posts.slice(indexOfFirstArticle, indexOfLastArticle);

  // Logika untuk mengubah halaman
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Container style={{ marginTop: '60px', paddingBottom: '60px' }}>
      <h1 className="mt-4 mb-4">List of Articles</h1>
      <Row>
        {currentArticles.map((article) => (
          <Col key={article.id} md={4} className="mb-4">
            <Card>
              <Card.Img variant="top" src={article.img} /> 
              <Card.Body>
                <Card.Title>{article.title}</Card.Title>
                <Link to={`/articles/${article.id}`}>
                  <Button variant="primary">Read Article</Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      {/* Paginasi */}
      {articlesData.posts.length > articlesPerPage && (
        <ul className="pagination justify-content-center">
          {Array.from({ length: Math.ceil(articlesData.posts.length / articlesPerPage) }).map((_, index) => (
            <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => paginate(index + 1)}>
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      )}
    </Container>
  );
};

export default Articles;
