import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './Skills.css';

const Skills = () => {
  let skills = require('../../api/skills.json');

  return (
    <section id="skills-section" className="education-experience pb-minus-70 section">
      <Container>
        <div className="justify-content-center text-center">
          <h3 className="section-title">Kemampuan<span>Saya</span></h3>
          <hr className="section-line-border" />
          <p className="section-sub-line">Saya memiliki kemampuan pada bidang berikut ini : </p>
        </div>
        <Row className="justify-content-center">
          {skills.map((skill, index) => (
            <Col md={2} key={index} className="mb-4">
              <Card className="skills-card">
                <Card.Body>
                <Card.Img variant="top" src={skill.icon} className="img-fluid" />
                  <Card.Title className="skills-card-title">{skill.keahlian}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Skills;
